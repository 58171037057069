import type { ArrowPlacement, PopoverPlacement } from '~/types/style-guide'

export const ARROW_PLACEMENTS: Record<PopoverPlacement, ArrowPlacement> = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
}

export const ARROW_BORDERS: Record<ArrowPlacement, string> = {
  top: '1px 0px 0px 1px',
  right: '1px 1px 0px 0px',
  bottom: '0px 1px 1px 0px',
  left: '0px 0px 1px 1px',
}
